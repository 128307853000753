import React from "react";
import { AxiosError } from "axios";
import classNames from "classnames";
import { useParams } from "react-router-dom";

import request from "request";
import Icon from "icons";
import Input from "components/input";
import Button from "components/button";
import Loading from "components/loading";
import prettierNumber from "utils/prettier-number";
import { useDispatch, useSelector } from "store/hooks";
import transcript from "utils/transcript";
import global from "global";
import styles from "./scoring.module.scss";

const Scoring = () => {
  const { id } = useParams();
  const contextApplication = useSelector((state) => state.application);
  const dispatch = useDispatch();
  const [macrodata, setMacrodata] = React.useState<global.macrodata>();

  const [pending, setPending] = React.useState(false);

  // Кредитные данные
  const [lendingType, setLendingType] = React.useState({
    key: "",
    value: "",
  });
  const [branch, setBranch] = React.useState({
    key: "",
    value: "",
  });
  const [district, setDistrict] = React.useState({
    key: "",
    value: "",
  });
  const [creditHistoriesNumber, setCreditHistoriesNumber] = React.useState("");
  const [clientType, setClientType] = React.useState("");
  const [workScope, setWorkScope] = React.useState({
    key: "",
    value: "",
  });
  // Данные клиента
  const [age, setAge] = React.useState("");
  const [education, setEducation] = React.useState({
    key: "",
    value: "",
  });
  const [gender, setGender] = React.useState("");
  const [maritalStatus, setMaritalStatus] = React.useState({
    key: "",
    value: "",
  });
  const [income, setIncome] = React.useState("");
  const [dependents, setDependents] = React.useState("");
  const [workExperience, setWorkExperience] = React.useState({
    key: "",
    value: "",
  });
  const [carAvailability, setCarAvailability] = React.useState("");
  const [housingAvailability, setHousingAvailability] = React.useState("");
  const [coborrower, setCoborrower] = React.useState("");

  const handleCalculate = () => {
    let timeout: NodeJS.Timeout;
    setPending(true);
    request
      .post(`/applications/${id}/scoring`, {
        gender_code: Number(gender),
        age: Number(age),
        income: Number(income),
        credit_amount_history: Number(creditHistoriesNumber),
        client_type_code: Number(clientType),
        dependants: Number(dependents),
        is_car_code: Number(carAvailability),
        is_realestate_code: Number(housingAvailability),
        co_borrower_code: Number(coborrower),
        product_eng: lendingType.key,
        branch_eng: branch.key,
        region_eng: district.key,
        marital_status_eng: maritalStatus.key,
        education_eng: education.key,
        working_area_eng: workScope.key,
        work_experience_cat: workExperience.key,
      })
      .then((res) => res.data)
      .then(() => {
        timeout = setTimeout(
          () => dispatch.application.FETCH_APPLICATION_BY_ID(id),
          1000
        );
      })
      .catch((err: AxiosError) => {
        alert((err.response?.data as any).detail);
      })
      .finally(() => setPending(false));
    return () => clearTimeout(timeout);
  };

  const handleClear = () => {
    setLendingType({
      key: "",
      value: "",
    });
    setBranch({
      key: "",
      value: "",
    });
    setDistrict({
      key: "",
      value: "",
    });
    setCreditHistoriesNumber("");
    setClientType("");
    setWorkScope({
      key: "",
      value: "",
    });
    setAge("");
    setEducation({
      key: "",
      value: "",
    });
    setGender("");
    setMaritalStatus({
      key: "",
      value: "",
    });
    setIncome("");
    setDependents("");
    setWorkExperience({
      key: "",
      value: "",
    });
    setCarAvailability("");
    setHousingAvailability("");
  };

  React.useEffect(() => {
    request
      .get("/admin/constant_values")
      .then((res) => res.data)
      .then((d) => setMacrodata(d));
  }, []);

  return (
    <form className={styles.home}>
      <div className={styles.fields}>
        <div className={styles.creditDataBox}>
          <div className={styles.title}>Кредитные данные</div>
          <div className={styles.list}>
            <div className={styles.item}>
              <div className={styles.key}>Сумма</div>
              <div className={styles.value}>
                {prettierNumber(contextApplication.data?.amount ?? 0)} ₸
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Срок</div>
              <div className={styles.value}>
                {contextApplication.data?.period} мес.
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Процентная ставка</div>
              <div className={styles.value}>
                {contextApplication.data?.interest_rate} %
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>ИНН</div>
              <div className={styles.value}>{contextApplication.data?.inn}</div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Порог доверия</div>
              <div className={styles.value}>
                {(
                  (contextApplication.data?.scoring_log?.threshold ??
                    macrodata?.threshold ??
                    0) * 100
                ).toFixed(0)}{" "}
                %
              </div>
            </div>
            {!!contextApplication.data?.scoring_log && (
              <>
                <div className={styles.item}>
                  <div className={styles.key}>Вид кредитования</div>
                  <div className={styles.value}>
                    {transcript.productEng.getValueFromKey(
                      contextApplication.data?.scoring_log.product_eng
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Филиал/Пункт</div>
                  <div className={styles.value}>
                    {transcript.branchEng.getValueFromKey(
                      contextApplication.data?.scoring_log.branch_eng
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Район</div>
                  <div className={styles.value}>
                    {transcript.regionEng.getValueFromKey(
                      contextApplication.data?.scoring_log.region_eng
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Количество кредитных историй</div>
                  <div className={styles.value}>
                    {contextApplication.data?.scoring_log.credit_amount_history}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Тип клиента</div>
                  <div className={styles.value}>
                    {transcript.clientTypeCode.getValueFromKey(
                      contextApplication.data.scoring_log.client_type_code
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Сфера деятельности</div>
                  <div className={styles.value}>
                    {transcript.workingAreaEng.getValueFromKey(
                      contextApplication.data.scoring_log.working_area_eng
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.creditDataBox}>
          <div className={styles.title}>Макро данные</div>
          <div className={styles.list}>
            <div className={styles.item}>
              <div className={styles.key}>Инфляция прод.товаров</div>
              <div className={styles.value}>
                {contextApplication.data?.scoring_log?.food_inflation ??
                  macrodata?.food_inflation}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Инфляция непрод.товаров</div>
              <div className={styles.value}>
                {contextApplication.data?.scoring_log?.nonfood_inflation ??
                  macrodata?.nonfood_inflation}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Инфляция услуг</div>
              <div className={styles.value}>
                {contextApplication.data?.scoring_log?.services_inflation ??
                  macrodata?.services_inflation}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Курс доллара</div>
              <div className={styles.value}>
                {contextApplication.data?.scoring_log?.exrate_usd_avg ??
                  macrodata?.exrate_usd_avg}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Средний доход населения</div>
              <div className={styles.value}>
                {contextApplication.data?.scoring_log?.avrate ??
                  macrodata?.avrate}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>ВВП</div>
              <div className={styles.value}>
                {contextApplication.data?.scoring_log?.gdp ?? macrodata?.gdp}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Цена на Brent нефть</div>
              <div className={styles.value}>
                {contextApplication.data?.scoring_log?.brent_oil_price ??
                  macrodata?.brent_oil_price}
              </div>
            </div>
          </div>
        </div>
        {!!contextApplication.data?.scoring_log && (
          <div className={styles.creditDataBox}>
            <div className={styles.title}>Данные клиента</div>
            <div className={styles.list}>
              <div className={styles.item}>
                <div className={styles.key}>Возраст</div>
                <div className={styles.value}>
                  {contextApplication.data.scoring_log.age}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Образование</div>
                <div className={styles.value}>
                  {transcript.educationEng.getValueFromKey(
                    contextApplication.data.scoring_log.education_eng
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Пол</div>
                <div className={styles.value}>
                  {transcript.genderСode.getValueFromKey(
                    contextApplication.data.scoring_log.gender_code
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Семейное положение</div>
                <div className={styles.value}>
                  {transcript.maritalStatusEng.getValueFromKey(
                    contextApplication.data.scoring_log.marital_status_eng
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Доход</div>
                <div className={styles.value}>
                  {prettierNumber(contextApplication.data.scoring_log.income)} ₸
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Иждивенцы</div>
                <div className={styles.value}>
                  {contextApplication.data.scoring_log.dependants} чел.
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Опыт работы</div>
                <div className={styles.value}>
                  {transcript.workExperienceCat.getValueFromKey(
                    contextApplication.data.scoring_log.work_experience_cat
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Наличие автомобиля</div>
                <div className={styles.value}>
                  {transcript.isCarСode.getValueFromKey(
                    contextApplication.data.scoring_log.is_car_code
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Наличие жилья</div>
                <div className={styles.value}>
                  {transcript.isRealEstateCode.getValueFromKey(
                    contextApplication.data.scoring_log.is_realestate_code
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Созаемщик</div>
                <div className={styles.value}>
                  {transcript.coBorrowerCode.getValueFromKey(
                    contextApplication.data.scoring_log.co_borrower_code
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {!contextApplication.data?.scoring_log && (
          <>
            <div className={styles.fieldGroup}>
              <div className={styles.fieldTitle}>Кредитные данные</div>
              <div className={styles.fieldList}>
                <Input
                  title="Вид Кредитования*"
                  id="lendingType"
                  value={lendingType.key}
                  type="select"
                  options={[
                    {
                      key: "agriculture",
                      value: "Cельскохозяйственная",
                    },
                    {
                      key: "business",
                      value: "Предпринимательская",
                    },
                    {
                      key: "consumer",
                      value: "Потребительская",
                    },
                  ]}
                  onSelect={(option) => setLendingType(option)}
                />
                <Input
                  title="Филиал/Пункт*"
                  id="branch"
                  value={branch.key}
                  type="select"
                  hasFilterText
                  options={[
                    {
                      key: "Almaty_oblast",
                      value: "Алматинский областной филиал",
                    },
                    {
                      key: "Almaty_town",
                      value: "Алматинский городской филиал",
                    },
                    { key: "Jambil", value: "Жамбылский областной филиал" },
                    {
                      key: "Kizilorda",
                      value: "Кызылординский областной филиал",
                    },
                    {
                      key: "Makhtaral",
                      value: "Махтаральский районный филиал",
                    },
                    {
                      key: "Ordabasin",
                      value: "Ордабасынский районный филиал",
                    },
                    { key: "Sariagash", value: "Сарыагашский районный филиал" },
                    { key: "Sayram", value: "Сайрамский районный филиал" },
                    { key: "Shymkent", value: "Шымкент" },
                    {
                      key: "Turkestan",
                      value: "Туркестанский городской филиал",
                    },
                  ]}
                  onSelect={(option) => setBranch(option)}
                />
                <Input
                  title="Район*"
                  id="district"
                  value={district.key}
                  type="select"
                  hasFilterText
                  options={[
                    { key: "Abay", value: "Абай" },
                    { key: "Akmarjan", value: "Акмаржан" },
                    { key: "Aksukent", value: "Аксукент" },
                    { key: "Aktobe", value: "Актобе" },
                    { key: "Alatau", value: "Алатау" },
                    { key: "Aral", value: "Арал" },
                    { key: "Aris", value: "Арыс" },
                    { key: "Asa", value: "Аса" },
                    { key: "Asyk_ata", value: "Асык ата" },
                    { key: "Atakent", value: "Атакент" },
                    { key: "Atameken", value: "Атамекен" },
                    { key: "Ayteke_bi", value: "Айтеке Би" },
                    { key: "B_momishili", value: "Б. Момышулы" },
                    { key: "Baydibek", value: "Байдибек" },
                    { key: "Bayirkum", value: "Байыркум" },
                    { key: "Bazarkakpa", value: "Базаркакпа" },
                    { key: "Birlik", value: "Бирлик" },
                    { key: "Center", value: "Центр" },
                    { key: "Darbaza", value: "Дарбаза" },
                    { key: "Esik", value: "Есик" },
                    { key: "Eurasia", value: "Евразия" },
                    { key: "Iassy", value: "Иассы" },
                    { key: "Ikan", value: "Икан" },
                    { key: "Intimak", value: "Ынтымак" },
                    { key: "Janakorgan", value: "Жанакорган" },
                    { key: "Janatas", value: "Жанатас" },
                    { key: "Jarkent", value: "Жаркент" },
                    { key: "Jetisay", value: "Жетысай" },
                    { key: "Juldiz", value: "Жулдыз" },
                    { key: "Kaplanbek", value: "Капланбек" },
                    { key: "Karabulak", value: "Карабулак" },
                    { key: "Karakum", value: "Каракум" },
                    { key: "Karasu", value: "Карасу" },
                    { key: "Karatas", value: "Каратас" },
                    { key: "Karatau", value: "Каратау" },
                    { key: "Karnak", value: "Карнак" },
                    { key: "Kaskelen", value: "Каскелен" },
                    { key: "Kaytpas", value: "Кайтпас" },
                    { key: "Kazakhstan", value: "Казахстан" },
                    { key: "Kazali", value: "Казалы" },
                    { key: "Kazygurt", value: "Казыгурт" },
                    { key: "Kentau", value: "Кентау" },
                    { key: "Keruen", value: "Керуен" },
                    { key: "Kizilkum", value: "Кызылкум" },
                    { key: "Koksu", value: "Коксу" },
                    { key: "Kolkent", value: "Колкент" },
                    { key: "Korday", value: "Кордай" },
                    { key: "Kulan", value: "Кулан" },
                    { key: "Kurkeles", value: "Куркелес" },
                    { key: "Lenger", value: "Ленгер" },
                    { key: "Maktaaral", value: "Мактаарал" },
                    { key: "Mankent", value: "Манкент МСБ" },
                    { key: "Merke", value: "Мерке" },
                    { key: "Mirzakent", value: "Мырзакент" },
                    { key: "Nursat", value: "Нурсат" },
                    { key: "Orangay", value: "Оранғай" },
                    { key: "Otirar", value: "Отырар" },
                    { key: "Rabat", value: "Рабат" },
                    { key: "Samal", value: "Самал" },
                    { key: "Sariagash", value: "Сарыагаш" },
                    { key: "Sastobe", value: "Састобе" },
                    { key: "Sayram", value: "Сайрам" },
                    { key: "Sayran", value: "Сайран" },
                    { key: "Shardara", value: "Шардара" },
                    { key: "Shaulder", value: "Шаульдер" },
                    { key: "Shelek", value: "Шелек" },
                    { key: "Shieli", value: "Шиели" },
                    { key: "Shubarsu", value: "Шубарсу" },
                    { key: "Sozak", value: "Созак" },
                    { key: "T_Riskulova", value: "Т.Рыскулова" },
                    { key: "Taldikorgan", value: "Талдыкорган" },
                    { key: "Taraz", value: "Тараз" },
                    { key: "Tekesu", value: "Текесу" },
                    { key: "Temirlan", value: "Темирлан" },
                    { key: "Tole_bi", value: "Толе би" },
                    { key: "Tortkol", value: "Тортколь" },
                    { key: "Turan", value: "Туран" },
                    { key: "Turkestan", value: "Туркестан" },
                    { key: "shymkala_msb", value: "Шымкала МСБ" },
                  ]}
                  onSelect={(option) => setDistrict(option)}
                />
                <Input
                  title="Количество кредитных историй"
                  value={creditHistoriesNumber}
                  min={0}
                  max={52}
                  step={1}
                  type="integer"
                  onInput={(value) => setCreditHistoriesNumber(value)}
                />
                <Input
                  title="Тип клиента"
                  id="clientType"
                  value={clientType}
                  type="select"
                  options={[
                    {
                      key: "0",
                      value: "Новый",
                    },
                    {
                      key: "1",
                      value: "Повторный",
                    },
                  ]}
                  onSelect={(option) => setClientType(String(option.key))}
                />
                <Input
                  title="Сфера деятельности"
                  id="workScope"
                  value={workScope.key}
                  type="select"
                  hasFilterText
                  options={[
                    { key: "agriculture", value: "Сельское хозяйство" },
                    { key: "animal_husbandry", value: "Животноводство" },
                    { key: "building", value: "Строительство" },
                    { key: "driver", value: "Водитель" },
                    { key: "employment", value: "Работодатель" },
                    {
                      key: "entrepreneur",
                      value: "Индивидуальный предприниматель",
                    },
                    { key: "housewife", value: "Домохозяйка" },
                    { key: "manufacture", value: "Производство" },
                    { key: "medicine", value: "Медицина" },
                    { key: "military", value: "Военный" },
                    { key: "public_officer", value: "Гос. служащий" },
                    { key: "retired", value: "Пенсионер" },
                    { key: "services", value: "Работа по найму" },
                    { key: "trade", value: "Торговля" },
                  ]}
                  onSelect={(option) => setWorkScope(option)}
                />
              </div>
            </div>
            <div className={styles.fieldGroup}>
              <div className={styles.fieldTitle}>Данные клиента</div>
              <div className={styles.fieldList}>
                <Input
                  title="Возраст"
                  value={age}
                  min={18}
                  max={73}
                  step={1}
                  type="integer"
                  onInput={(value) => setAge(value)}
                />
                <Input
                  title="Образование"
                  id="education"
                  value={education.key}
                  type="select"
                  options={[
                    {
                      key: "higher_education",
                      value: "Высшее",
                    },
                    {
                      key: "no_education",
                      value: "Без образования",
                    },
                    {
                      key: "secondary_education",
                      value: "Среднее специальное",
                    },
                  ]}
                  onSelect={(option) => setEducation(option)}
                />
                <Input
                  title="Пол"
                  id="gender"
                  value={gender}
                  type="select"
                  options={[
                    {
                      key: "0",
                      value: "Женщина",
                    },
                    {
                      key: "1",
                      value: "Мужчина",
                    },
                  ]}
                  onSelect={(option) => setGender(String(option.key))}
                />
                <Input
                  title="Семейное положение"
                  id="maritalStatus"
                  value={maritalStatus.key}
                  type="select"
                  options={[
                    {
                      key: "divorced",
                      value: "Разведен / Разведена",
                    },
                    {
                      key: "married",
                      value: "Женат / Замужем",
                    },
                    {
                      key: "single",
                      value: "Холост / Холостой",
                    },
                    {
                      key: "widow",
                      value: "Вдова / Вдовец",
                    },
                  ]}
                  onSelect={(option) => setMaritalStatus(option)}
                />
                <Input
                  title="Доход"
                  value={income}
                  step={1}
                  type="integer"
                  onInput={(value) => setIncome(value)}
                />
                <Input
                  title="Иждивенцы"
                  value={dependents}
                  step={1}
                  type="integer"
                  onInput={(value) => setDependents(value)}
                />
                <Input
                  title="Опыт работы"
                  id="workExperience"
                  value={workExperience.key}
                  type="select"
                  options={[
                    { key: "from1to3", value: "От 1 года до 3 лет" },
                    { key: "from3to5", value: "От 3 года до 5 лет" },
                    { key: "morethan5", value: "больше 5 лет" },
                    { key: "no_exp", value: "нет опыта" },
                  ]}
                  onSelect={(option) => setWorkExperience(option)}
                />
                <Input
                  title="Наличие автомобиля"
                  id="carAvailability"
                  value={carAvailability}
                  type="select"
                  options={[
                    {
                      key: "0",
                      value: "Нет",
                    },
                    {
                      key: "1",
                      value: "Да",
                    },
                  ]}
                  onSelect={(option) => setCarAvailability(String(option.key))}
                />
                <Input
                  title="Наличие жилья"
                  id="housingAvailability"
                  value={housingAvailability}
                  type="select"
                  options={[
                    {
                      key: "0",
                      value: "Нет",
                    },
                    {
                      key: "1",
                      value: "Да",
                    },
                  ]}
                  onSelect={(option) =>
                    setHousingAvailability(String(option.key))
                  }
                />
                <Input
                  title="Созаемщик"
                  id="coborrower"
                  value={coborrower}
                  type="select"
                  options={[
                    {
                      key: "0",
                      value: "Нет",
                    },
                    {
                      key: "1",
                      value: "Да",
                    },
                  ]}
                  onSelect={(option) => setCoborrower(String(option.key))}
                />
              </div>
            </div>
            <div className={styles.footerBox}>
              <div className={styles.buttonGroup}>
                <Button onClick={handleCalculate}>Расчитать</Button>
                <Button onClick={handleClear} color="red">
                  Очистить
                </Button>
              </div>
              <div className={styles.info}>
                Внимание! Заполните все поля корректно.
                <br />
                После получения результата, повторный <span>cкоринг</span> над
                заявкой провести нельзя.
              </div>
            </div>
          </>
        )}
      </div>
      <div className={styles.result}>
        {pending && (
          <div className={styles.resultBox}>
            <Loading size={50} />
          </div>
        )}
        {!!contextApplication.data?.scoring_log && (
          <div className={styles.resultBox}>
            <div className={styles.resultBox_title}>Результат Scoring</div>
            <Icon
              size={120}
              name={
                contextApplication.data.scoring_log.is_approved
                  ? "tickCircle"
                  : "closeFilled"
              }
              className={classNames(
                contextApplication.data.scoring_log.is_approved
                  ? styles.resultBox_icon_success
                  : styles.resultBox_icon_error
              )}
            />
            <div className={styles.resultBox_resultText}>
              {contextApplication.data.scoring_log.is_approved
                ? "Одобряется"
                : "Не одобряется"}{" "}
              {contextApplication.data.scoring_log.prediction}%
            </div>
            <div className={styles.resultBox_resultText}>
              Порог:{" "}
              {(contextApplication.data.scoring_log.threshold * 100).toFixed(0)}
              %
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default Scoring;
