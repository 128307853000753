import React from "react";
import { Outlet, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "store/hooks";
import Loading from "components/loading";
import styles from "./application.module.scss";
import Navbar from "components/navbar";

const ApplicationLayout = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const contextApplication = useSelector((state) => state.application);
  const user = useSelector((state) => state.user);

  React.useLayoutEffect(() => {
    dispatch.application.FETCH_APPLICATION_BY_ID(id);
  }, [dispatch.application, id]);

  return (
    <div className={styles.applicationLayout} id="application-layout">
      {contextApplication.pending && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}
      {!contextApplication.pending && (
        <div className={styles.container}>
          {user.role !== "underwriter" && (
            <div className={styles.navbar}>
              <Navbar
                resultBlocked={contextApplication.data?.is_approved === null}
              />
            </div>
          )}
          <div className={styles.content}>
            <Outlet />
          </div>
        </div>
      )}
    </div>
  );
};

export default ApplicationLayout;
