import React from "react";

import Input from "components/input";
import Button from "components/button";
import { useSelector } from "store/hooks";
import request from "request";
import styles from "./create-modal.module.scss";
import users from "../users";

const EditUserModal = ({
  onClose,
  onEdit,
  selectedUser,
}: {
  selectedUser?: users.user;
  onClose: () => void;
  onEdit: () => void;
}) => {
  const user = useSelector((state) => state.user);
  const [login, setLogin] = React.useState(selectedUser?.login ?? "");
  const [password1, setPassword1] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [role, setRole] = React.useState({
    key: selectedUser?.role ?? "",
    value: "",
  });
  const [pending, setPending] = React.useState(false);
  const [validError, setValidError] = React.useState<{
    name: "login" | "password1" | "password2" | "role";
    value: string;
  } | null>(null);

  const handleEdit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (login.trim().length < 5) {
      return setValidError({
        name: "login",
        value: "Минимальная длина логин 5 символов",
      });
    } else if (password1.trim().length !== 0 && password1.trim().length < 6) {
      return setValidError({
        name: "password1",
        value: "Минимальная длина пароля 6 символов",
      });
    } else if (password1.trim() !== password2.trim()) {
      return setValidError({
        name: "password2",
        value: "Пароли не совпадают",
      });
    } else if (role.key === "") {
      return setValidError({
        name: "role",
        value: "Роль обязателен",
      });
    } else setValidError(null);
    setPending(true);

    let body: any = {};
    if (password1.trim() !== "") body.password = password1;
    body.login = login;
    body.role = role.key;

    request
      .put("/admin/users/" + selectedUser?.id, body)
      .then(() => onEdit())
      .finally(() => setPending(false));
  };

  console.log(user.role);
  return (
    <div className={styles.modal} onMouseDown={onClose}>
      <form
        onSubmit={handleEdit}
        className={styles.modalWrap}
        onMouseDown={(event) => event.stopPropagation()}
      >
        <div className={styles.title}>Изменить пользователя</div>
        <div className={styles.inputs}>
          <div className={styles.input}>
            <Input
              title="Логин"
              type="string"
              value={login}
              onInput={(value) => setLogin(value)}
            />
            {validError?.name === "login" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Пароль"
              type="password"
              value={password1}
              onInput={(value) => setPassword1(value)}
            />
            {validError?.name === "password1" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Повторите пароль"
              type="password"
              value={password2}
              onInput={(value) => setPassword2(value)}
            />
            {validError?.name === "password2" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          {user.role === "root" && (
            <div className={styles.input}>
              <Input
                id="role"
                title="Роль"
                type="select"
                value={role.key}
                onSelect={(option) => setRole(option)}
                options={[
                  {
                    key: "expert",
                    value: "Кредитный эксперт",
                  },
                  {
                    key: "underwriter",
                    value: "Андеррайтер",
                  },
                  {
                    key: "admin",
                    value: "Админ",
                  },
                ]}
              />
              {validError?.name === "role" && (
                <div className={styles.inputError}>{validError.value}</div>
              )}
            </div>
          )}
          {user.role === "admin" && (
            <div className={styles.input}>
              <Input
                id="role"
                title="Роль"
                type="select"
                value={role.key}
                onSelect={(option) => setRole(option)}
                options={[
                  {
                    key: "expert",
                    value: "Кредитный эксперт",
                  },
                  {
                    key: "underwriter",
                    value: "Андеррайтер",
                  },
                ]}
              />
              {validError?.name === "role" && (
                <div className={styles.inputError}>{validError.value}</div>
              )}
            </div>
          )}
        </div>
        <div className={styles.buttonGroup}>
          <Button color="orange" onClick={onClose}>
            Отмена
          </Button>
          <Button type="submit" color="green" pending={pending}>
            Изменить
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditUserModal;
