import classNames from "classnames";
import Button from "components/button";
import Input from "components/input";
import Loading from "components/loading";
import Icon from "icons";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import request from "request";
import { useDispatch, useSelector } from "store/hooks";
import baseURL from "utils/base-url";
import styles from "./ocr.module.scss";

const OCR = () => {
  const navigate = useNavigate();

  const [ocrPending, setOcrPending] = React.useState(false);
  const [filePKB, setFilePKB] = React.useState<File | null>();
  const [fileKASPI, setFileKASPI] = React.useState<File | null>();
  const [commonIncome, setCommonIncome] = React.useState("");
  const [bankType, setBankType] = React.useState({
    key: "kaspi",
    value: "Каспи",
  });

  const dispatch = useDispatch();
  const contextApplication = useSelector((state) => state.application);
  const user = useSelector((state) => state.user);

  const { id } = useParams();

  const isCorrectFields = () => {
    if (bankType.key === "other" && commonIncome.trim() === "") {
      toast.error("Введите общий доход!");
      return false;
    }
    if (!fileKASPI) {
      toast.error("Заполенения файла банка обязательно!");
      return false;
    }
    if (!filePKB) {
      toast.error("Заполенения файла pkb обязательно!");
      return false;
    }

    return true;
  };

  const handleRunOCR = () => {
    if (!isCorrectFields()) return;
    setOcrPending(true);
    const fd = new FormData();
    fd.append("application_id", id!);
    if (filePKB) fd.append("pkb", filePKB);
    if (fileKASPI) fd.append("statement", fileKASPI);
    if (bankType) fd.append("statement_type", bankType.key);

    request({
      url: "/applications/ocr",
      method: "POST",
      data: fd,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(async () => {
        if (bankType.key === "other")
          await request.put("/applications/" + id!, {
            average_income_6_months: commonIncome,
          });
        dispatch.application.FETCH_APPLICATION_BY_ID(id);
      })
      .finally(() => setOcrPending(false));
  };

  const handleClear = () => {
    setFileKASPI(null);
    setFileKASPI(null);
    setCommonIncome("");
    setBankType({
      key: "",
      value: "",
    });
  };

  const handleFileChangePKB = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) setFilePKB(event.target.files[0]);
  };

  const handleFileChangeKASPI = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) setFileKASPI(event.target.files[0]);
  };

  const handleUnderwriterSubmit = () => {
    request
      .put("/applications/" + id!, {
        average_income_6_months: commonIncome,
      })
      .then(() => navigate("/"));
  };

  React.useEffect(() => {
    setCommonIncome(String(contextApplication.data?.ocr_log?.average_income_6_months ?? ""));
  }, [contextApplication, user.role]);

  return (
    <div className={styles.ocr}>
      {user.role !== "underwriter" && !contextApplication.data?.ocr_log && !ocrPending && (
        <div className={styles.inputBox}>
          <div className={styles.textInput}>
            <div className={styles.textInputTitle}>Тип банка:</div>
            <Input
              type="select"
              value={bankType.key}
              onSelect={(option) => setBankType(option)}
              options={[
                {
                  key: "kaspi",
                  value: "Каспи",
                },
                {
                  key: "jusan",
                  value: "Жусан старый",
                },
                {
                  key: "jusan_new",
                  value: "Жусан новый",
                },
                {
                  key: "halyk",
                  value: "Халык старый",
                },
                {
                  key: "halyk_new",
                  value: "Халык новый",
                },
                {
                  key: "bck",
                  value: "БЦК старый",
                },
                {
                  key: "bck_new",
                  value: "БЦК новый",
                },
                {
                  key: "other",
                  value: "Другой банк",
                },
              ]}
            />
          </div>
          {bankType.key === "other" && (
            <label className={styles.textInput}>
              <div className={styles.textInputTitle}>Введите общий доход клиента:</div>
              <Input type="integer" value={commonIncome} onInput={(v) => setCommonIncome(v)} />
            </label>
          )}
          <label className={styles.fileInput}>
            <div className={styles.fileInputTitle}>{bankType.value}:</div>
            {fileKASPI?.name ?? (
              <div className={styles.fileInputDescription}>
                загрузить <span className={styles.bold}>.pdf</span> документ
              </div>
            )}
            <input accept="application/pdf" type="file" onChange={handleFileChangeKASPI} />
          </label>
          <label className={styles.fileInput}>
            <div className={styles.fileInputTitle}>ПКБ:</div>
            {filePKB?.name ?? (
              <div className={styles.fileInputDescription}>
                загрузить <span className={styles.bold}>.pdf</span> документ
              </div>
            )}
            <input accept="application/pdf" type="file" onChange={handleFileChangePKB} />
          </label>
          <div className={styles.footerBox}>
            <div className={styles.buttonGroup}>
              <Button onClick={handleRunOCR}>Запустить OCR</Button>
              <Button onClick={handleClear} color="red">
                Очистить
              </Button>
            </div>
            <div className={styles.info}>
              Внимание! Заполните оба файла корректно.
              <br />
              После получения результата, изменить
              <br />
              <span>OCR</span> записи нельзя.
            </div>
          </div>
        </div>
      )}
      <div className={styles.result}>
        {ocrPending && (
          <div className={styles.resultBox}>
            <Loading size={50} />
          </div>
        )}
        {!!contextApplication.data?.ocr_log && !ocrPending && (
          <>
            {user.role !== "underwriter" &&
              contextApplication.data?.ocr_log.is_approved !== null && (
                <div className={styles.resultBox}>
                  <div className={styles.resultBox_title}>Результат OCR</div>
                  <Icon
                    size={120}
                    name={
                      contextApplication.data.ocr_log.is_approved ? "tickCircle" : "closeFilled"
                    }
                    className={classNames(
                      contextApplication.data.ocr_log.is_approved
                        ? styles.resultBox_icon_success
                        : styles.resultBox_icon_error
                    )}
                  />
                  <div className={styles.resultBox_resultText}>
                    {contextApplication.data.ocr_log.is_approved ? "Одобряется" : "Не одобряется"}
                  </div>
                </div>
              )}

            {user.role !== "underwriter" &&
              contextApplication.data?.ocr_log.is_approved === null && (
                <div className={styles.resultBox}>
                  <div className={styles.resultBox_title} style={{ textAlign: "center" }}>
                    Пожалуйста, дождитесь
                    <br />
                    подтверждения андеррайтера!
                  </div>
                </div>
              )}

            {user.role === "underwriter" &&
              !contextApplication.data?.underwriter_has_assigned_value && (
                <div className={styles.inputBox}>
                  <label className={styles.textInput}>
                    <div className={styles.textInputTitle}>Общий доход клиента:</div>
                    <Input
                      type="integer"
                      value={commonIncome}
                      onInput={(v) => setCommonIncome(v)}
                    />
                  </label>
                  <div className={styles.footerBox}>
                    <div className={styles.buttonGroup}>
                      <Button onClick={handleUnderwriterSubmit}>Подтвердить</Button>
                    </div>
                  </div>
                </div>
              )}
            <div className={styles.resultBox}>
              <div className={styles.resultBox_title}>Вложения</div>
              <div className={styles.links}>
                {(!contextApplication.data?.files || contextApplication.data?.files.length === 0) &&
                  "Вложений нет"}
                {contextApplication.data?.files?.map((file) => (
                  <a href={`${baseURL}/static/${file.id}.pdf`} target="_blank" rel="noreferrer">
                    <Button color="blue">{file.document_type}</Button>
                  </a>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OCR;
