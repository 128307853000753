import React from "react";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";

import Button from "components/button";
import ButtonLoader from "components/button-loader";
import Input from "components/input";
import global from "global";
import request from "request";
import { useSelector } from "store/hooks";
import transcript from "utils/transcript";
import modalStyles from "./components/create-modal.module.scss";
import CreateModal from "./components/create-modal";
import EditUserModal from "./components/edit-user-modal";
import styles from "./users.module.scss";
import users from "./users";

let timeout: NodeJS.Timeout;

const Users = () => {
  const resultRef = React.useRef<HTMLDivElement | null>(null);
  const user = useSelector((state) => state.user);

  const [search, setSearch] = React.useState("");
  const [pending, setPending] = React.useState(false);
  const [newUserModal, setNewUserModal] = React.useState(false);
  const [editUserModal, setEditUserModal] = React.useState<users.user>();
  const [data, setData] = React.useState<users.user[]>([]);

  const handleLoadRows = React.useCallback(async () => {
    setPending(true);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      resultRef.current?.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      request
        .get(`/admin/users?login=${search.trim()}`)
        .then((res) => res.data)
        .then((d) => {
          setData(d);
        })
        .finally(() => setPending(false));
    }, 400);
  }, [search]);

  React.useEffect(() => {
    handleLoadRows();
  }, [handleLoadRows]);

  return (
    <div className={styles.results} ref={resultRef}>
      <div className={styles.container}>
        <div className={styles.buttonGroup}>
          <Button color="green" onClick={() => setNewUserModal(true)}>
            Новый пользователь
          </Button>
        </div>
        <div className={styles.search}>
          <Input
            type="string"
            value={search}
            onInput={(v) => setSearch(v)}
            placeholder="Поиск"
          />
        </div>
        {pending && (
          <div className={styles.tableLoading}>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
          </div>
        )}
        {!pending && data.length !== 0 && (
          <div className={styles.table}>
            {data
              .filter((u) => u.id !== user.id)
              .map((item) => (
                <div className={styles.tableRowWrap} key={item.id}>
                  <div className={styles.tableRow}>
                    <div className={styles.item}>
                      <span className={styles.span}>Дата создания</span>
                      {new Date(item.created_at).toLocaleString()}
                    </div>
                    <div className={styles.item}>
                      <span className={styles.span}>Логин</span>
                      {item.login}
                    </div>
                    <div className={styles.item}>
                      <span className={styles.span}>Роль</span>
                      {transcript.userRole.getValueFromKey(item.role)}
                    </div>
                    <div
                      className={classNames(
                        styles.item,
                        item.is_active ? styles.green : styles.red
                      )}
                    >
                      <span className={styles.span}>Статус</span>
                      {item.is_active ? "Активен" : "Заблокирован"}
                    </div>
                    <div className={styles.item}>
                      {item.role !== "root" && item.role !== user.role && (
                        <Button onClick={() => setEditUserModal(item)}>
                          Изменить
                        </Button>
                      )}
                    </div>
                    <div className={styles.item}>
                      {item.role !== "root" && item.role !== user.role && (
                        <BlockButton user={item} setData={setData} />
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
        {!pending && data.length === 0 && (
          <div className={styles.tableIsEmptyInfo}>Нет пользователей</div>
        )}
      </div>
      <CSSTransition
        in={!!newUserModal}
        timeout={150}
        unmountOnExit
        classNames={{
          enter: modalStyles.modalEnter,
          enterActive: modalStyles.modalEnterActive,
          exit: modalStyles.modalExit,
          exitActive: modalStyles.modalExitActive,
        }}
      >
        <CreateModal
          onClose={() => setNewUserModal(false)}
          onCreate={() => {
            setNewUserModal(false);
            handleLoadRows();
          }}
        />
      </CSSTransition>
      <CSSTransition
        in={!!editUserModal}
        timeout={150}
        unmountOnExit
        classNames={{
          enter: modalStyles.modalEnter,
          enterActive: modalStyles.modalEnterActive,
          exit: modalStyles.modalExit,
          exitActive: modalStyles.modalExitActive,
        }}
      >
        <EditUserModal
          selectedUser={editUserModal}
          onClose={() => setEditUserModal(undefined)}
          onEdit={() => {
            setEditUserModal(undefined);
            handleLoadRows();
          }}
        />
      </CSSTransition>
    </div>
  );
};

const BlockButton = ({
  user,
  setData,
}: {
  user: global.user;
  setData: React.Dispatch<React.SetStateAction<users.user[]>>;
}) => {
  const [pending, setPending] = React.useState(false);

  const handleToggleUserBlock = (id: string) => {
    setPending(true);
    request
      .put(`/admin/users/${id}/block`)
      .then(() => {
        setData((prev) =>
          prev.map((user) => ({
            ...user,
            is_active: user.id === id ? !user.is_active : user.is_active,
          }))
        );
      })
      .finally(() => setPending(false));
  };

  return (
    <Button
      pending={pending}
      color={user.is_active ? "red" : "blue"}
      onClick={(e) => {
        e.stopPropagation();
        handleToggleUserBlock(user.id);
      }}
    >
      {user.is_active ? "Заблокировать" : "Активировать"}
    </Button>
  );
};

export default Users;
