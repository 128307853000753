const transcript = {
  productEng: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Сельскохозяйственный":
          return "agriculture";
        case "Предпринимательский":
          return "business";
        case "Потребительский":
          return "consumer";
        default:
          return "";
      }
    },
    getValueFromKey: (text: string) => {
      switch (text) {
        case "agriculture":
          return "Сельскохозяйственный";
        case "business":
          return "Предпринимательский";
        case "consumer":
          return "Потребительский";
        default:
          return "";
      }
    },
  },
  clientTypeCode: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Новый":
          return 0;
        case "Повторный":
          return 1;
        default:
          return "";
      }
    },
    getValueFromKey: (text: string | number) => {
      switch (text) {
        case 0:
        case "0":
          return "Новый";
        case 1:
        case "1":
          return "Повторный";
        default:
          return "";
      }
    },
  },
  regionEng: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Абай":
          return "Abay";
        case "Акмаржан":
          return "Akmarjan";
        case "Аксукент":
          return "Aksukent";
        case "Актобе":
          return "Aktobe";
        case "Алатау":
          return "Alatau";
        case "Арал":
          return "Aral";
        case "Арыс":
          return "Aris";
        case "Аса":
          return "Asa";
        case "Асык ата":
          return "Asyk_ata";
        case "Атакент":
          return "Atakent";
        case "Атамекен":
          return "Atameken";
        case "Айтеке Би":
          return "Ayteke_bi";
        case "Б. Момышулы":
          return "B_momishili";
        case "Байдибек":
          return "Baydibek";
        case "Байыркум":
          return "Bayirkum";
        case "Базаркакпа":
          return "Bazarkakpa";
        case "Бирлик":
          return "Birlik";
        case "Центр":
          return "Center";
        case "Дарбаза":
          return "Darbaza";
        case "Есик":
          return "Esik";
        case "Евразия":
          return "Eurasia";
        case "Иассы":
          return "Iassy";
        case "Икан":
          return "Ikan";
        case "Ынтымак":
          return "Intimak";
        case "Жанакорган":
          return "Janakorgan";
        case "Жанатас":
          return "Janatas";
        case "Жаркент":
          return "Jarkent";
        case "Жетысай":
          return "Jetisay";
        case "Жулдыз":
          return "Juldiz";
        case "Капланбек":
          return "Kaplanbek";
        case "Карабулак":
          return "Karabulak";
        case "Каракум":
          return "Karakum";
        case "Карасу":
          return "Karasu";
        case "Каратас":
          return "Karatas";
        case "Каратау":
          return "Karatau";
        case "Карнак":
          return "Karnak";
        case "Каскелен":
          return "Kaskelen";
        case "Кайтпас":
          return "Kaytpas";
        case "Казахстан":
          return "Kazakhstan";
        case "Казалы":
          return "Kazali";
        case "Казыгурт":
          return "Kazygurt";
        case "Кентау":
          return "Kentau";
        case "Керуен":
          return "Keruen";
        case "Кызылкум":
          return "Kizilkum";
        case "Коксу":
          return "Koksu";
        case "Колкент":
          return "Kolkent";
        case "Кордай":
          return "Korday";
        case "Кулан":
          return "Kulan";
        case "Куркелес":
          return "Kurkeles";
        case "Ленгер":
          return "Lenger";
        case "Мактаарал":
          return "Maktaaral";
        case "Манкент МСБ":
          return "Mankent";
        case "Мерке":
          return "Merke";
        case "Мырзакент":
          return "Mirzakent";
        case "Нурсат":
          return "Nursat";
        case "Оранғай":
          return "Orangay";
        case "Отырар":
          return "Otirar";
        case "Рабат":
          return "Rabat";
        case "Самал":
          return "Samal";
        case "Сарыагаш":
          return "Sariagash";
        case "Састобе":
          return "Sastobe";
        case "Сайрам":
          return "Sayram";
        case "Сайран":
          return "Sayran";
        case "Шардара":
          return "Shardara";
        case "Шаульдер":
          return "Shaulder";
        case "Шелек":
          return "Shelek";
        case "Шиели":
          return "Shieli";
        case "Шубарсу":
          return "Shubarsu";
        case "Созак":
          return "Sozak";
        case "Т.Рыскулова":
          return "T_Riskulova";
        case "Талдыкорган":
          return "Taldikorgan";
        case "Тараз":
          return "Taraz";
        case "Текесу":
          return "Tekesu";
        case "Темирлан":
          return "Temirlan";
        case "Толе би":
          return "Tole_bi";
        case "Тортколь":
          return "Tortkol";
        case "Туран":
          return "Turan";
        case "Туркестан":
          return "Turkestan";
        case "Шымкала МСБ":
          return "shymkala_msb";
        default:
          return "";
      }
    },
    getValueFromKey: (text: string) => {
      switch (text) {
        case "Abay":
          return "Абай";
        case "Akmarjan":
          return "Акмаржан";
        case "Aksukent":
          return "Аксукент";
        case "Aktobe":
          return "Актобе";
        case "Alatau":
          return "Алатау";
        case "Aral":
          return "Арал";
        case "Aris":
          return "Арыс";
        case "Asa":
          return "Аса";
        case "Asyk_ata":
          return "Асык ата";
        case "Atakent":
          return "Атакент";
        case "Atameken":
          return "Атамекен";
        case "Ayteke_bi":
          return "Айтеке Би";
        case "B_momishili":
          return "Б. Момышулы";
        case "Baydibek":
          return "Байдибек";
        case "Bayirkum":
          return "Байыркум";
        case "Bazarkakpa":
          return "Базаркакпа";
        case "Birlik":
          return "Бирлик";
        case "Center":
          return "Центр";
        case "Darbaza":
          return "Дарбаза";
        case "Esik":
          return "Есик";
        case "Eurasia":
          return "Евразия";
        case "Iassy":
          return "Иассы";
        case "Ikan":
          return "Икан";
        case "Intimak":
          return "Ынтымак";
        case "Janakorgan":
          return "Жанакорган";
        case "Janatas":
          return "Жанатас";
        case "Jarkent":
          return "Жаркент";
        case "Jetisay":
          return "Жетысай";
        case "Juldiz":
          return "Жулдыз";
        case "Kaplanbek":
          return "Капланбек";
        case "Karabulak":
          return "Карабулак";
        case "Karakum":
          return "Каракум";
        case "Karasu":
          return "Карасу";
        case "Karatas":
          return "Каратас";
        case "Karatau":
          return "Каратау";
        case "Karnak":
          return "Карнак";
        case "Kaskelen":
          return "Каскелен";
        case "Kaytpas":
          return "Кайтпас";
        case "Kazakhstan":
          return "Казахстан";
        case "Kazali":
          return "Казалы";
        case "Kazygurt":
          return "Казыгурт";
        case "Kentau":
          return "Кентау";
        case "Keruen":
          return "Керуен";
        case "Kizilkum":
          return "Кызылкум";
        case "Koksu":
          return "Коксу";
        case "Kolkent":
          return "Колкент";
        case "Korday":
          return "Кордай";
        case "Kulan":
          return "Кулан";
        case "Kurkeles":
          return "Куркелес";
        case "Lenger":
          return "Ленгер";
        case "Maktaaral":
          return "Мактаарал";
        case "Mankent":
          return "Манкент МСБ";
        case "Merke":
          return "Мерке";
        case "Mirzakent":
          return "Мырзакент";
        case "Nursat":
          return "Нурсат";
        case "Orangay":
          return "Оранғай";
        case "Otirar":
          return "Отырар";
        case "Rabat":
          return "Рабат";
        case "Samal":
          return "Самал";
        case "Sariagash":
          return "Сарыагаш";
        case "Sastobe":
          return "Састобе";
        case "Sayram":
          return "Сайрам";
        case "Sayran":
          return "Сайран";
        case "Shardara":
          return "Шардара";
        case "Shaulder":
          return "Шаульдер";
        case "Shelek":
          return "Шелек";
        case "Shieli":
          return "Шиели";
        case "Shubarsu":
          return "Шубарсу";
        case "Sozak":
          return "Созак";
        case "T_Riskulova":
          return "Т.Рыскулова";
        case "Taldikorgan":
          return "Талдыкорган";
        case "Taraz":
          return "Тараз";
        case "Tekesu":
          return "Текесу";
        case "Temirlan":
          return "Темирлан";
        case "Tole_bi":
          return "Толе би";
        case "Tortkol":
          return "Тортколь";
        case "Turan":
          return "Туран";
        case "Turkestan":
          return "Туркестан";
        case "shymkala_msb":
          return "Шымкала МСБ";
        default:
          return "";
      }
    },
  },
  branchEng: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Алматинский областной филиал":
          return "Almaty_oblast";
        case "Алматинский городской филиал":
          return "Almaty_town";
        case "Жамбылский областной филиал":
          return "Jambil";
        case "Кызылординский областной филиал":
          return "Kizilorda";
        case "Махтаральский районный филиал":
          return "Makhtaral";
        case "Ордабасынский районный филиал":
          return "Ordabasin";
        case "Сарыагашский районный филиал":
          return "Sariagash";
        case "Сайрамский районный филиал":
          return "Sayram";
        case "Шымкент":
          return "Shymkent";
        case "Туркестанский городской филиал":
          return "Turkestan";
        default:
          return "";
      }
    },
    getValueFromKey: (text: string) => {
      switch (text) {
        case "Almaty_oblast":
          return "Алматинский областной филиал";
        case "Almaty_town":
          return "Алматинский городской филиал";
        case "Jambil":
          return "Жамбылский областной филиал";
        case "Kizilorda":
          return "Кызылординский областной филиал";
        case "Makhtaral":
          return "Махтаральский районный филиал";
        case "Ordabasin":
          return "Ордабасынский районный филиал";
        case "Sariagash":
          return "Сарыагашский районный филиал";
        case "Sayram":
          return "Сайрамский районный филиал";
        case "Shymkent":
          return "Шымкент";
        case "Turkestan":
          return "Туркестанский городской филиал";
        default:
          return "";
      }
    },
  },
  workingAreaEng: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Сельское хозяйство":
          return "agriculture";
        case "Животноводство":
          return "animal_husbandry";
        case "Строительство":
          return "building";
        case "Водитель":
          return "driver";
        case "Работодатель":
          return "employment";
        case "Индивидуальный предприниматель":
          return "entrepreneur";
        case "Домохозяйка":
          return "housewife";
        case "Производство":
          return "manufacture";
        case "Медицина":
          return "medicine";
        case "Военный":
          return "military";
        case "Гос. служащий":
          return "public_officer";
        case "Пенсионер":
          return "retired";
        case "Работа по найму":
          return "services";
        case "Торговля":
          return "trade";
        default:
          return "";
      }
    },
    getValueFromKey: (text: string) => {
      switch (text) {
        case "agriculture":
          return "Сельское хозяйство";
        case "animal_husbandry":
          return "Животноводство";
        case "building":
          return "Строительство";
        case "driver":
          return "Водитель";
        case "employment":
          return "Работодатель";
        case "entrepreneur":
          return "Индивидуальный предприниматель";
        case "housewife":
          return "Домохозяйка";
        case "manufacture":
          return "Производство";
        case "medicine":
          return "Медицина";
        case "military":
          return "Военный";
        case "public_officer":
          return "Гос. служащий";
        case "retired":
          return "Пенсионер";
        case "services":
          return "Работа по найму";
        case "trade":
          return "Торговля";
        default:
          return "";
      }
    },
  },
  educationEng: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Высшее":
          return "higher_education";
        case "Без образования":
          return "no_education";
        case "Среднее специальное":
          return "secondary_education";
        default:
          return "";
      }
    },
    getValueFromKey: (text: string) => {
      switch (text) {
        case "higher_education":
          return "Высшее";
        case "no_education":
          return "Без образования";
        case "secondary_education":
          return "Среднее специальное";
        default:
          return "";
      }
    },
  },
  genderСode: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Мужчина":
          return 1;
        case "Женщина":
          return 0;
        default:
          return "";
      }
    },
    getValueFromKey: (text: string | number) => {
      switch (text) {
        case 1:
        case "1":
          return "Мужчина";
        case 0:
        case "0":
          return "Женщина";
        default:
          return "";
      }
    },
  },
  maritalStatusEng: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Разведен / Разведена":
          return "divorced";
        case "Женат / Замужем":
          return "married";
        case "Холост / Холостой":
          return "single";
        case "Вдова / Вдовец":
          return "widow";
        default:
          return "";
      }
    },
    getValueFromKey: (text: string | number) => {
      switch (text) {
        case "divorced":
          return "Разведен / Разведена";
        case "married":
          return "Женат / Замужем";
        case "single":
          return "Холост / Холостой";
        case "widow":
          return "Вдова / Вдовец";
        default:
          return "";
      }
    },
  },
  workExperienceCat: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "От 1 года до 3 лет":
          return "from1to3";
        case "От 3 года до 5 лет":
          return "from3to5";
        case "Больше 5 лет":
          return "morethan5";
        case "Нет опыта":
          return "no_exp";
        default:
          return "";
      }
    },
    getValueFromKey: (text: string | number) => {
      switch (text) {
        case "from1to3":
          return "От 1 года до 3 лет";
        case "from3to5":
          return "От 3 года до 5 лет";
        case "morethan5":
          return "Больше 5 лет";
        case "no_exp":
          return "Нет опыта";
        default:
          return "";
      }
    },
  },
  isCarСode: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Да":
          return 1;
        case "Нет":
          return 0;
        default:
          return "";
      }
    },
    getValueFromKey: (text: string | number) => {
      switch (text) {
        case 1:
        case "1":
          return "Да";
        case 0:
        case "0":
          return "Нет";
        default:
          return "";
      }
    },
  },
  isRealEstateCode: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Да":
          return 1;
        case "Нет":
          return 0;
        default:
          return "";
      }
    },
    getValueFromKey: (text: string | number) => {
      switch (text) {
        case 1:
        case "1":
          return "Да";
        case 0:
        case "0":
          return "Нет";
        default:
          return "";
      }
    },
  },
  coBorrowerCode: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Да":
          return 1;
        case "Нет":
          return 0;
        default:
          return "";
      }
    },
    getValueFromKey: (text: string | number) => {
      switch (text) {
        case 1:
        case "1":
          return "Да";
        case 0:
        case "0":
          return "Нет";
        default:
          return "";
      }
    },
  },
  userRole: {
    getValueFromKey: (text: string) => {
      switch (text) {
        case "admin":
          return "Администратор";
        case "root":
          return "ROOT";
        case "underwriter":
          return "Андеррайтер";
        default:
          return "Кредитный эксперт";
      }
    },
  },
};

export default transcript;
