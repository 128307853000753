import isProdDeploy from "./is-prod-deploy";

// const devURL = "http://192.168.100.24:5000";
const devURL = "https://arnur.eastasia.cloudapp.azure.com/dev";
// const devURL = "http://10.30.230.69:8000";

const prodURL = "https://arnur.eastasia.cloudapp.azure.com/prod";
// const prodURL = "http://10.30.230.69:8000";

const baseURL = isProdDeploy ? prodURL : devURL;

export default baseURL;
