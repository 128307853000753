import React from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";

import Icon from "icons";
import global from "global";
import transcript from "utils/transcript";
import prettierNumber from "utils/prettier-number";
import { useSelector } from "store/hooks";
import styles from "./result.module.scss";

Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
});

const pdfStyles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    padding: "10px",
  },
  section: {
    marginBottom: "10px",
    marginTop: "10px",
  },
  heading: {
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  text: {
    fontSize: "14px",
  },
  table: {
    backgroundColor: "#fff",
  },
  tr: {
    display: "flex",
    flexDirection: "row",
  },
  td: {
    border: "1px solid #000",
    width: "100%",
    padding: "5px 10px",
    fontSize: "14px",
  },
  result: {
    fontSize: "18px",
    fontWeight: 700,
  },
  bottom: {
    margin: "10px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

const Result = () => {
  const contextApplication = useSelector((state) => state.application);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (contextApplication.data?.is_approved === null) navigate("/");
  }, [contextApplication, navigate]);

  return (
    <div className={styles.result}>
      <div className={styles.pdf}>
        <PDFViewer style={{ width: "100%", height: "calc(100vh - 150px)" }}>
          <Pdf contextApplication={contextApplication} />
        </PDFViewer>
      </div>
      <div className={styles.resultBoxGroup}>
        <div className={styles.resultBox}>
          <div className={styles.resultBox_title}>Результат кредита</div>
          <Icon
            size={120}
            name={
              contextApplication.data?.is_approved
                ? "tickCircle"
                : "closeFilled"
            }
            className={classNames(
              contextApplication.data?.is_approved
                ? styles.resultBox_icon_success
                : styles.resultBox_icon_error
            )}
          />
          <div className={styles.resultBox_resultText}>
            {contextApplication.data?.is_approved ? "Одобрено" : "Отказано"}
          </div>
        </div>
      </div>
    </div>
  );
};

const Pdf = ({
  contextApplication,
}: {
  contextApplication: global.contextApplication;
}) => {
  return (
    <Document>
      {/* Скоринг */}
      {contextApplication.data?.scoring_log && (
        <Page style={pdfStyles.page}>
          <View style={pdfStyles.heading}>
            <Text>Скоринг</Text>
          </View>
          <View style={pdfStyles.section}>
            <Text style={pdfStyles.text}>
              Дата/Время:{" "}
              {new Date(
                contextApplication.data?.created_at ?? ""
              ).toLocaleString()}
            </Text>
            <Text style={pdfStyles.text}>
              ID запроса: {contextApplication.data?.id}
            </Text>
            <View style={pdfStyles.table}>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>ИНН клиента</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data?.inn}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Доход</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {prettierNumber(
                      contextApplication.data?.scoring_log?.income ?? 0
                    )}{" "}
                    тенге
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Род деятельности</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.workingAreaEng.getValueFromKey(
                      contextApplication.data?.scoring_log?.working_area_eng ??
                        ""
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Вид кредитования</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.productEng.getValueFromKey(
                      contextApplication.data?.scoring_log?.product_eng ?? ""
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Количество кредитных историй</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {
                      contextApplication.data?.scoring_log
                        ?.credit_amount_history
                    }
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Тип клиента</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.clientTypeCode.getValueFromKey(
                      contextApplication.data?.scoring_log?.client_type_code ??
                        ""
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Образование</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.educationEng.getValueFromKey(
                      contextApplication.data?.scoring_log?.education_eng ?? ""
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Сумма кредита</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {prettierNumber(contextApplication.data?.amount ?? 0)} тенге
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Процентная ставка</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data?.interest_rate.toFixed(2)} %
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Срок</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data?.period} мес.</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Созаёмщик</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.coBorrowerCode.getValueFromKey(
                      contextApplication.data?.scoring_log?.co_borrower_code ??
                        ""
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Автомобиль</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.isCarСode.getValueFromKey(
                      contextApplication.data?.scoring_log?.is_car_code ?? ""
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Жильё</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.isRealEstateCode.getValueFromKey(
                      contextApplication.data?.scoring_log
                        ?.is_realestate_code ?? ""
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Возраст</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data?.scoring_log?.age}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Пол</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.genderСode.getValueFromKey(
                      contextApplication.data?.scoring_log?.gender_code ?? ""
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Семейное положение</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.maritalStatusEng.getValueFromKey(
                      contextApplication.data?.scoring_log
                        ?.marital_status_eng ?? ""
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Район</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.regionEng.getValueFromKey(
                      contextApplication.data?.scoring_log?.region_eng ?? ""
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Филиал</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.branchEng.getValueFromKey(
                      contextApplication.data?.scoring_log?.branch_eng ?? ""
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Иждивенцы</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data?.scoring_log?.dependants}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Опыт</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.workExperienceCat.getValueFromKey(
                      contextApplication.data?.scoring_log
                        ?.work_experience_cat ?? ""
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Автор</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data.created_by.login}</Text>
                </View>
              </View>
            </View>
            <View style={pdfStyles.bottom}>
              <Text style={pdfStyles.result}>
                Результат:{" "}
                {contextApplication.data?.scoring_log.is_approved
                  ? "Одобряется"
                  : "Отказано"}{" "}
                {contextApplication.data?.scoring_log?.prediction}%
              </Text>
              <Text style={pdfStyles.result}>
                Порог:{" "}
                {(
                  Number(contextApplication.data?.scoring_log?.threshold) * 100
                ).toFixed(0)}
                %
              </Text>
            </View>
          </View>
        </Page>
      )}
      {/* OCR */}
      {contextApplication.data?.ocr_log && (
        <Page style={pdfStyles.page}>
          <View style={pdfStyles.heading}>
            <Text>Результат OCR</Text>
          </View>
          <View style={pdfStyles.section}>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Общий доход</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {prettierNumber(
                    contextApplication.data?.ocr_log?.overall_income ?? 0
                  )}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Доход за последние 6 месяцев</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {prettierNumber(
                    contextApplication.data?.ocr_log?.income_6_months ?? 0
                  )}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Средний доход за последние 6 месяцев</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {prettierNumber(
                    contextApplication.data?.ocr_log?.average_income_6_months ??
                      0
                  )}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Доход клиента для кредита (60% от дохода)</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {prettierNumber(
                    contextApplication.data?.ocr_log?.income_for_credit ?? 0
                  )}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Текущий месячный платёж</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {prettierNumber(
                    contextApplication.data?.ocr_log?.current_monthly_payment ??
                      0
                  )}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Предполагаемая кредитоспособность клиента</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {prettierNumber(
                    contextApplication.data?.ocr_log
                      ?.current_creditworthiness ?? 0
                  )}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Предполагаемый платёж</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {prettierNumber(
                    contextApplication.data?.ocr_log?.estimated_payment ?? 0
                  )}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Автор</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{contextApplication.data.created_by.login}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Результат</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {contextApplication.data?.ocr_log?.is_approved
                    ? "Одобрено"
                    : "Отказано"}
                </Text>
              </View>
            </View>
          </View>
        </Page>
      )}
      {/* Макроданные */}
      {contextApplication.data?.scoring_log && (
        <Page style={pdfStyles.page}>
          <Text style={pdfStyles.heading}>
            Макроэкономические показатели на{" "}
            {new Date(
              contextApplication.data?.created_at ?? ""
            ).toLocaleDateString()}
          </Text>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>ПРОДОВОЛЬСТВЕННЫЕ ТОВАРЫ</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {contextApplication.data?.scoring_log?.food_inflation}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>НЕПРОДОВОЛЬСТВЕННЫЕ ТОВАРЫ</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {contextApplication.data?.scoring_log?.nonfood_inflation}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>УСЛУГИ</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {contextApplication.data?.scoring_log?.services_inflation}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>КУРС</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {contextApplication.data?.scoring_log?.exrate_usd_avg}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>СРЕДНЯЯ ЗАРПЛАТА</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {contextApplication.data?.scoring_log?.services_inflation}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>ВВП</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{contextApplication.data?.scoring_log?.gdp}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>ЦЕНА НА НЕФТЬ (BRENT)</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {contextApplication.data?.scoring_log?.brent_oil_price}
                </Text>
              </View>
            </View>
          </View>
        </Page>
      )}
    </Document>
  );
};

export default React.memo(Result, () => true);
